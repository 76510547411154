<template>
  <div class="shoukuan">
    <paihangbang class="left"></paihangbang>
    <div class="center"></div>
    <bingtu class="right"></bingtu>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import bingtu from './cpns/bingtu/bingtu.vue'
import paihangbang from './cpns/paihangbang/paihangbang.vue'
export default defineComponent({
  props: {

  },
  components: {
    bingtu,
    paihangbang
  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.shoukuan {
  display: flex;

  .left,
  .right {
    flex: 2;
  }

  .center {
    flex: 1;
  }
}
</style>




