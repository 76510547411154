<template>
  <div class="xiaoshoue">
    <div class="title">消耗额（元）</div>
    <div id="leftChart" :style="{ width: '100%', height: '300px' }"></div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onMounted } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    onMounted(() => {
      let leftChart = echarts.init(document.getElementById("leftChart"));
      leftChart.setOption(
        {
          xAxis: {
            type: 'category',
            data: ['1号', '2号', '3号', '4号', '5号', '6号', '7号']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [820, 932, 901, 934, 1290, 1330, 1320],
              type: 'line',
              smooth: true
            }
          ]
        }
      )
    })

    return {

    }

  }
})
</script>

<style scoped lang="less">
.xiaoshoue {
  margin-top: 20px;
  background-color: #fff;
  .title{
    margin-bottom: 20px;
    padding:30px 20px;

  }
}
</style>




