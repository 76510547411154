<template>
  <div class="jiaoyi-kanban">
    <div class="title">
      <div class="left">交易看板</div>
      <div class="tabs">
        <template :key="item.id" v-for="(item, index) in menus">
          <div :class="{ active: currentIndex == index }" @click="handleItemClick(index)" class="item">
            {{ item.title }}
          </div>
        </template>
      </div>
    </div>
    <template v-if="currentIndex == 0">
      <shoukuan></shoukuan>
    </template>
    <template v-else-if="currentIndex == 1">
      消耗构成
    </template>
    <template v-else>
      销售构成
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import shoukuan from './cpns/shoukuan/shoukuan.vue'

export default defineComponent({
  props: {

  },
  components: {
    shoukuan
  },
  setup() {
    const currentIndex = ref(0)

    const menus = [
      {
        title: "收款方式",
        id: 1
      }, {
        title: "消耗构成",
        id: 2
      }, {
        title: "销售构成",
        id: 3
      },
    ]

    const handleItemClick = index => {
      currentIndex.value = index
    }


    return {
      currentIndex,
      menus,
      handleItemClick
    }

  }
})
</script>

<style scoped lang="less">
.jiaoyi-kanban {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px 20px;
  min-height: 300px;


  .active {
    background-color: rgb(24, 144, 255);
    color: white;
  }

  .title {
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;
      cursor: pointer;

      &>div:nth-child(1) {
        border-left: 1px solid rgb(24, 144, 255);
      }

      .item {
        line-height: 30px;
        padding: 0 20px;
        border: 1px solid rgb(24, 144, 255);
        font-size: 13px;
        border-left: none;
      }
    }
  }
}
</style>




