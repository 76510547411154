<template>
  <div class="data-list">
    <div class="item">
      <div class="title">实际首款金额(元)</div>
      <div class="price">
        <span v-if="detail.today_shijiShoukuan != null">{{ ((detail.today_shijiShoukuan)/100).toFixed(2) }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.yestday_shjishoukuan != null">{{ ((detail.yestday_shjishoukuan)/100).toFixed(2) }}</span>
      </div>
    </div>

    <div class="item">
      <div class="title">客户消耗金额(元)</div>
      <div class="price">
        <span v-if="detail.today_shijiXiaohao != null">{{ ((detail.today_shijiXiaohao)/100).toFixed(2) }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.yestday_shijiXiaohao != null">{{ ((detail.yestday_shijiXiaohao)/100).toFixed(2) }}</span>
      </div>
    </div>

    <div class="item">
      <div class="title">成交客户数</div>
      <div class="price">
        <span v-if="detail.today_buyer != null">{{ detail.today_buyer }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span v-if="detail.yestday_buyer != null">{{ detail.yestday_buyer }}</span>
        <span>0.00</span>
      </div>
    </div>

    <div class="item">
      <div class="title">新增会员数</div>
      <div class="price">
        <span v-if="detail.today_newuser != null">{{ detail.today_newuser }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.today_newuser != null">{{ detail.today_newuser }}</span>
      </div>
    </div>

    <div class="item">
      <div class="title">实际退款金额(元)</div>
      <div class="price">

        <span v-if="detail.today_tuikuan != null">{{ ((detail.today_tuikuan)/100).toFixed(2) }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.yestday_tuikuan != null">{{ ((detail.yestday_tuikuan)/100).toFixed(2) }}</span>

      </div>
    </div>

    <div class="item">
      <div class="title">客户消耗退款金额(元)</div>
      <div class="price">
        <span v-if="detail.today_xiaohaotuikuan != null">{{ ((detail.today_xiaohaotuikuan)/100).toFixed(2) }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.today_xiaohaotuikuan != null">{{ ((detail.today_xiaohaotuikuan)/100).toFixed(2) }}</span>
      </div>
    </div>

    <div class="item">
      <div class="title">客单价(元)</div>
      <div class="price">
        <span v-if="detail.today_avrage != null">{{ ((detail.today_avrage)/100).toFixed(2) }}</span>
        <el-icon class="up">
          <top />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.yestday_avarage != null">{{((detail.yestday_avarage)/100).toFixed(2) }}</span>
      </div>
    </div>

    <div class="item">
      <div class="title">开卡充值用户数</div>
      <div class="price">
        <span v-if="detail.today_nonserve_buyer != null">{{ detail.today_nonserve_buyer }}</span>
        <el-icon class="down">
          <bottom />
        </el-icon>
      </div>
      <div class="contrast">
        <span>昨日:</span>
        <span v-if="detail.yestday_nonserve_buyer != null">{{ detail.yestday_nonserve_buyer }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    detail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {

  },
  setup() {

    const test = ref(0)
    return {
      test
    }

  }
})
</script>

<style scoped lang="less">
.data-list {
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 20%;
    margin-bottom: 46px;
    min-width: 150px;

    // display: flex;
    // align-items: center;
    // flex-direction: column;
    .title {
      color: rgba(176, 176, 176, 1);
      font-size: 14px;
    }

    .price {
      color: rgba(80, 80, 80, 1);
      font-size: 22px;
      font-weight: bold;
      display: flex;
      align-items: center;
      margin: 8px 0;

      .up {
        color: rgb(104, 190, 117);
      }

      .down {
        color: rgba(220, 68, 68, 1);
      }
    }

    .contrast {
      color: rgba(176, 176, 176, 1);
      font-size: 14px;
    }
  }
}
</style>




