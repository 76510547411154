export const timeType=[
  {
    label:'日报',
    value:0,
  }, {
    label:'月报',
    value:1,
  }
]

export const breadcrumbList = [
  {
    name: '数据管理',
    path: ''
  },
  {
    name: '数据明细',
    path: ''
  }
]
