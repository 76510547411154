export const contentTableConfig = {
  propList: [
    { prop: 'ruleName', label: '实收构成', minWidth: '100', slotName: 'ruleName' },
    {
      prop: 'shop',
      label: '实际收款金额',
      minWidth: '100',
      slotName: 'shop'
    },
    {
      prop: 'role',
      label: '占比',
      minWidth: '100',
      slotName: 'role'
    },

  ],
  showIndexColumn: true,
  showSelectColumn: false
}
