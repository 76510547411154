<template>
  <div class="tips">
    <el-carousel
      indicator-position='none'
      direction="vertical"
      height="48px"
      class="el-carousel"
      :interval='3000'
    >
      <el-carousel-item
        v-for="item in 4"
        :key="item"
      >
        <div class="item">
          <div>
            <el-icon>
              <chat-round />
            </el-icon>
            <span>这里是全局提醒事项 全局提醒事项可以滚动播放</span>
          </div>
          <div>了解详情</div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.el-carousel {
  background-color: rgba(234, 225, 255, 1);
  color: rgba(80, 80, 80, 1);
  box-shadow: 0px 1px 2px 0px rgba(184, 184, 184, 0.5);
  font-size: 14px;
  border-radius: 5px;
  padding: 0 20px;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
</style>




