<template>
  <div class="top-content">
    <div class="top-form">
      <el-select @change="handleShopChange" v-model="ruleForm.area" class="m-2" placeholder="统计范围" size="large">
        <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId" />
      </el-select>

      <el-select @change="handleTimeTypeChange" v-model="ruleForm.timeType" class="m-2" placeholder="时间类别" size="large">
        <el-option v-for="item in timeType" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <template v-if="ruleForm.timeType == 0">
        <div>
          <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="date" placeholder="选择日期"
            :size="size" />
        </div>
      </template>
      <template v-else>
        <div>
          <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="month" placeholder="选择月份" />
        </div>
      </template>

      <div class="search-wrap">
        <el-button class="add-staff" type="primary" @click="handleSearchClick">筛选</el-button>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { getBelongShop } from '@/service/main/staff'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import { timeType } from '../../config'
import dayjs from 'dayjs'
import emitter from '@/utils/eventbus'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {

    const ruleForm = reactive({
      timeType: 0,
      date: "",
      area: "0",
      keyword: ""
    })

    const handleDateChange = value => {
      let type = ruleForm.timeType
      let date = dayjs(value).format('YYYY-MM-DD')
      let yearmonth = dayjs(value).format('YYYY-MM')
      let dayTotal = dayjs(date).daysInMonth()

      if (type == 0) {
        cache.setCache('indexscoreStart', date + ' 00:00:00')
        cache.setCache('indexscoreEnd', date + ' 23:59:59')
      } else if (type == 1) {
        cache.setCache('indexscoreStart', date + ' 00:00:00')
        cache.setCache('indexscoreEnd', yearmonth + '-' + dayTotal + ' 23:59:59')
      }
      emitsfunc()
    }

    const shopList = ref([])
    const handleShopChange = shopid => {
      cache.setCache('indextichengShopid', shopid)
      emitsfunc()
    }

    const emitsfunc = () => {

    }

    const handleTimeTypeChange = value => {
      cache.setCache('indextichengTimeType', value)
      ruleForm.date = ''
      cache.setCache('indexscoreStart', "")
      cache.setCache('indexscoreEnd', "")
      emitsfunc()
    }

    const initPage = async () => {
      const res = await getBelongShop(COMPANY_ID)
      let list = res.data.list
      list.unshift({
        shopName: "总部",
        shopId: "0"
      })
      shopList.value = res.data.list
      let tichengShopid = cache.getCache('indextichengShopid')
      if (String(tichengShopid).length == 0) {
        cache.setCache('indextichengShopid', '0')
      } else {
        ruleForm.area = tichengShopid
      }
      let tichengTimeType = cache.getCache('indextichengTimeType')
      if (tichengTimeType != undefined) {
        ruleForm.timeType = tichengTimeType
      }

      let start = cache.getCache('indexscoreStart')
      let date = dayjs(new Date()).format('YYYY-MM-DD')
      let yearmonth = dayjs(new Date()).format('YYYY-MM')

      if (tichengTimeType == 0 && start != undefined) {
        ruleForm.date = dayjs(start).format('YYYY-MM-DD')
      } else if (tichengTimeType == 0 && start == undefined) {
        ruleForm.date = date
      } else if (tichengTimeType == 1 && start != undefined) {
        ruleForm.date = dayjs(start).format('YYYY-MM')
      } else if (tichengTimeType == 1 && start == undefined) {
        ruleForm.date = yearmonth
      }

    }
    initPage()


    return {
      ruleForm,
      shopList,
      handleShopChange,
      timeType,
      handleDateChange,
      handleTimeTypeChange,
      emitsfunc
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #fff;
  padding: 10px 20px;

  &:deep(.el-input) {
    margin-right: 10px;
  }

  .bottom-form {
    display: flex;
    align-items: center;

    .down-record {
      margin-left: 10px;
      font-size: 13px;
      color: rgb(64, 158, 255);
      cursor: pointer;
    }
  }

  .top-form {
    display: flex;
    justify-content: flex-end;

    &:deep(.el-select) {
      margin-right: 10px;
    }

    .search-wrap {
      margin-left: 10px;
    }

    .download {
      margin-right: 10px;
    }

  }

}
</style>




