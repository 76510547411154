<template>
  <div class="xiaoshoue">
    <div class="title">消耗额（元）</div>
    <div id="shoukuanbingtu" :style="{ width: '100%', height: '400px' }"></div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onMounted } from 'vue'
import * as echarts from 'echarts'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    onMounted(() => {
      let leftChart = echarts.init(document.getElementById("shoukuanbingtu"));
      leftChart.setOption(
        {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            bottom: '0%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '20',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: '微信 20%' },
                { value: 735, name: '支付宝 45.5%' },
                { value: 580, name: '抖音 34.7%' },
                { value: 484, name: '云闪付 34.5%' },
                { value: 300, name: '其他 1.2%' }
              ]
            }
          ]
        }
      )
    })

    return {

    }

  }
})
</script>

<style scoped lang="less">
.xiaoshoue {
  margin-top: 20px;
  background-color: #fff;

  .title {
    line-height: 40px;
    font-size: 13px;
  }
}
</style>




