<template>
  <div class="paihangbang">
    <pageContent :contentTableConfig="contentTableConfig" :isShowTooltip="false" :dataList="dataList">
    </pageContent>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import pageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
export default defineComponent({
  props: {

  },
  components: {
    pageContent
  },
  setup() {


    const dataList=ref([])

    return {
      dataList,
      contentTableConfig
    }

  }
})
</script>

<style scoped lang="less">
.paihangbang {
  margin-top: 20px;
}
</style>




