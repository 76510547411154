<template>
  <div class="today-data">
    <div class="title">
      <div class="name">今日数据</div>
      <div class="update-time">
        <span>更新时间:</span>
        <span>{{ $store.state.index.updateTime }}</span>
      </div>
    </div>
    <data-list :detail="detail"></data-list>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import DataList from './data-list/data-list'
export default defineComponent({
  props: {
    detail: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  components: {
    DataList
  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.today-data {
  background-color: white;
  margin: 16px 0;
  padding: 20px;

  .title {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .name {
      color: rgba(80, 80, 80, 1);
      font-size: 16px;
      font-weight: bold;
    }

    .update-time {
      margin-left: 8px;
      color: rgba(176, 176, 176, 1);
      font-size: 14px;
    }
  }
}
</style>




