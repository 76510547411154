<template>
  <div class="pending">
    <div class="title">代办事项</div>
    <div class="pending-list">
      <div class="pending-item">
        <span>0.00</span>
        <span>预约待服务</span>
      </div>
      <div class="pending-item">
        <span>0</span>
        <span>待发货商品</span>
      </div>
      <div class="pending-item">
        <span>0</span>
        <span>待付款订单</span>
      </div>
      <div class="pending-item">
        <span>0</span>
        <span>低库存商品</span>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.pending {
  background-color: white;
  margin: 16px 0;
  padding: 20px;

  .title {
    color: rgba(80, 80, 80, 1);
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  .pending-list {
    padding: 0 10px;
    display: flex;

    .pending-item {
      width: 10%;
      min-width: 100px;
      display: flex;
      flex-direction: column;

      span:nth-child(1) {
        color: rgba(80, 80, 80, 1);
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 14px;
      }

      span:nth-child(2) {
        color: rgba(80, 80, 80, 1);
        font-size: 14px;
      }
    }
  }
}
</style>




