<template>
  <div class="index">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <!-- <tips :detail="detail"></tips>
    <today-data :detail="detail"></today-data>
    <pedding :detail="detail"></pedding> -->
    <div class="index-content">
      <topContent></topContent>
      <yeji></yeji>
      <xiaoshoue></xiaoshoue>
      <kehu></kehu>
      <jiedailiang></jiedailiang>
      <jiaoyikanban ></jiaoyikanban>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Tips from './cpns/tips/tips'
import TodayData from './cpns/today-data/today-data'
import Features from './cpns/features/features'
import Pedding from './cpns/pending/pending'
import { getIndexDetail } from '@/service/main/index'
import topContent from './cpns/top-content/top-content.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import yeji from './cpns/yeji/yeji.vue'
import kehu from './cpns/kehu/kehu.vue'
import xiaoshoue from './cpns/echars/xiaoshoue/xiaoshoue.vue'
import jiedailiang from './cpns/echars/jiedailaing/jiedailaing.vue'
import jiaoyikanban from './cpns/echars/jiaoyi-kanban/jiaoyi-kanban.vue'

export default defineComponent({
  props: {

  },
  components: {
    Tips,
    TodayData,
    Features,
    Pedding,
    topContent,
    Breadcrumb,
    yeji,
    kehu,
    xiaoshoue,
    jiedailiang,
    jiaoyikanban
  },
  setup() {

    const detail = ref({})
    const initPage = async () => {
      const res = await getIndexDetail()
      detail.value = res.data
      // console.log(detail.value);
    }
    initPage()

    return {
      detail,
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.index {
  background-color: rgb(239, 239, 239);
  height: 100%;
  min-width: 1080px;

  .index-content {
    margin: 20px;
  }
}
</style>
