<template>
  <div class="features">
    <div class="title">常用功能</div>
    <div class="features-list">
      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">开单收银</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">快速开卡</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">会员充值</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">快速验码</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">新增预约</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">营业报表</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">员工业绩</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">资金充值</span>
      </div>

      <div class="features-item">
        <img
          class="icon"
          src="~assets/img/main/index/index_icon1.png"
          alt=""
        >
        <span class="text">访问网店</span>
      </div>

    </div>
  </div>
</template>

<script lang="js">
import {defineComponent, reactive, ref} from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.features {
  background-color: white;
  margin: 16px 0;
  padding: 20px;
  .title {
    color: rgba(80, 80, 80, 1);
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .features-list {
    display: flex;
    flex-wrap: wrap;

    .features-item {
      width: 12%;
      display: flex;
      align-items: center;
      margin-bottom: 36px;
      .icon {
        width: 33px;
        height: 33px;
        margin-right: 10px;
      }
    }
  }
}
</style>




