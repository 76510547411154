<template>
  <div class="yeji">
    <div class="title">业绩预览</div>
    <div class="content">
      <div class="left">
        <div class="item">
          <div class="key">
            <div>消耗额(元)</div>
            <div>客户数(个)</div>
            <div>客单价(元)</div>
            <div>产品(元)</div>
          </div>
          <div class="value">
            <div>1250.00<span class="sp1">=</span></div>
            <div>100 <span class="sp2">x</span></div>
            <div>10 <span class="sp3">+</span></div>
            <div>250</div>
          </div>
        </div>

        <div class="item">
          <div class="key">
            <div>收入</div>
            <div>单次付费(元)</div>
            <div>消耗额(元)</div>
            <div></div>
          </div>

          <div class="value">

            <div>1250.00 <span class="sp1">=</span></div>
            <div>100 <span class="sp2">+</span></div>
            <div>12</div>
            <div></div>
          </div>
        </div>
      </div>

      <div class="right">
        <div class="item">
          <div class="key">
            <div>销售额(元)</div>
            <div>新客销售额(元)</div>
            <div>老客销售额(元)</div>
            <div>退款金额(元)</div>
          </div>
          <div class="value">
            <div>1250.00<span class="sp1">=</span></div>
            <div>100 <span class="sp2">x</span></div>
            <div>10 <span class="sp3">+</span></div>
            <div>250</div>
          </div>
        </div>

        <div class="item">
          <div class="key">
            <div>实收</div>
            <div>单次付费(元)</div>
            <div>销售额(元)</div>
            <div>产品(元)</div>
          </div>
          <div class="value">
            <div>1250.00<span class="sp1">=</span></div>
            <div>100 <span class="sp2">x</span></div>
            <div>10 <span class="sp3">+</span></div>
            <div>250</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.yeji {
  background-color: #fff;
  margin-top: 20px;
  padding: 30px 20px;

  .content {
    display: flex;
    font-size: 13px;

    .left {
      border-right: 1px solid rgb(206, 204, 204);
    }

    .left,
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      .item {
        padding: 24px;

        .value {
          color: rgba(80, 80, 80, 1);
          font-size: 22px;
          font-weight: bold;

          div {
            text-align: center;
          }

          span {
            display: inline-block;
            position: relative;
          }

          .sp1 {
            left: 23%;
          }

          .sp2 {
            left: 32%;
          }

          .sp3 {
            left: 34%;
          }

          &>div {
            flex: 3;
          }
        }

        .key {
          margin-bottom: 20px;

          div {
            text-align: center;
          }
        }

        &>div {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;

          div {
            flex: 1;
          }
        }

      }
    }
  }

  .title {
    font-size: 16px;


  }
}
</style>




